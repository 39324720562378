import axios from "axios";

export default {
  namespaced: true,
  state: {
    messages: [],
    chatOpened: true,
  },
  mutations: {
    push_new_message(state, val) {
      state.messages.unshift(val);
    },
    toggleChatOpen(state) {
      state.chatOpened = !state.chatOpened;
      localStorage.setItem("chat-state", state.chatOpened);
    },
  },
  actions: {
    fetch_messages(context) {
      axios
        .get("/api/chat", {
          params: { channel_id: context.rootState.channels.current_channel.id },
        })
        .then((response) => {
          context.state.messages = response.data;
        });
    },
    send_message(context, message) {
      axios
        .post("/api/chat", {
          body: message,
          channel_id: context.rootState.channels.current_channel.id,
        })
        .then(() => {
          //context.state.messages.unshift(response.data)
        });
    },
    toggleChatOpen({ commit }) {
      commit("toggleChatOpen");
    },
    restoreChatState({ state }) {
      let storedState = localStorage.getItem("chat-state");
      if (storedState) {
        state.chatOpened = JSON.parse(storedState) === true;
      }
    },
  },
  getters: {
    chatOpened: (state) => state.chatOpened,
  },
};
