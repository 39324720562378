import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    playback: null,
    votes: {
      up: { pct: 0, n: 0 },
      down: { pct: 0 },
      votes: [],
    },
    disabled: false,
    timeslot: null,
    bid_amount: 0,
    youtube: "",
    time_remaining_timeslot: "-",
    time_remaining_auction: "-",
  },
  mutations: {
    set_player_state: function (state, val) {
      state.playback = val;
    },
    set_votes: function (state, val) {
      state.votes = val;
    },
    reset_votes(state) {
      state.votes = { up: { pct: 0, n: 0 }, down: { pct: 0 }, votes: [] };
    },
    set_timeslot(state, val) {
      if (state.timeslot && val && state.timeslot.id === val.id) {
        state.bid_amount = Math.max(state.bid_amount, val.max_bid + 1)
      } else if (val) {
        state.bid_amount = val.max_bid ? val.max_bid + 1 : 1
      }
      state.timeslot = val;
    },
  },
  getters: {
    votes: (state) => state.votes,
    playback: (state) => state.playback,
    timeslot: (state) => state.timeslot,
    is_winner: (state, getters, rootState, rootGetters) => {
      return (
        state.timeslot &&
        rootGetters["user/user"].id === state.timeslot.winner_id
      );
    },
    ended: (state) => Vue.moment(state.timeslot.date_ended),
    closed: (state) => Vue.moment(state.timeslot.date_closed),
    ends: (state) => Vue.moment(state.timeslot.date_ends),
    closes: (state) => Vue.moment(state.timeslot.date_closes),
    started: (state) => Vue.moment(state.timeslot.date_started),
    auction_is_active: (state, getters) => {
      let now = Vue.moment();
      return now >= getters.started && !state.timeslot.date_ended;
    },
  },
  actions: {
    update(context) {
      if (!context.state.timeslot) return;
      let now = Vue.moment();
      // let ended = Vue.moment(context.getters.ended);
      // let closed = Vue.moment(context.getters.closed);
      let ends = Vue.moment(context.getters.ends);
      let closes = Vue.moment(context.getters.closes);
      context.state.time_remaining_auction =
        now > ends ? "00:00" : Vue.moment(ends.diff(now)).format("mm:ss");
      context.state.time_remaining_timeslot =
        now > closes
          ? "00:00"
          : Vue.moment(closes.diff(now)).format("mm:ss");
    },
    vote(context, v) {
      return axios
        .post("/api/vote", { value: v, playback: context.state.playback.id })
        .then(() => {});
    },
    bid(context) {
      return axios
        .post("/api/bid-timeslot", {
          timeslot: context.state.timeslot.id,
          amount: context.state.bid_amount,
        })
        .then(() => {});
    },
    set_video(context) {
      return axios
        .post("/api/set-timeslot-video", {
          timeslot: context.state.timeslot.id,
          youtube: Vue.prototype.$utils.to_youtube_id(context.state.youtube),
        })
        .then(() => {});
    },
    cancel_playback(context) {
      return axios.post("/api/cancel-playback", {
        playback: context.state.playback.id,
      });
    },
  },
};
