import axios from "axios";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import User from "./User";
import Player from "./Player";
import Notifications from "./Notifications";
import Chat from "./Chat";
import Channels from "./Channels";
import Categories from "./Categories";

import Websocket from "./Websocket";

const store = new Vuex.Store({
  state: {
    alerts: [],
    init: false,

    timeout: 0,
    attempts: 1,

    //status_message: '',
    data: {},

    leaderboard: []
  },
  getters: {
    alerts: (state) => state.alerts,
  },
  mutations: {
    set_leaderboard(state, val) {
      //state.leaderboard = val.sort((a, b) => a.id - b.id)
      state.leaderboard = val
    },
    set_init: function (state, val) {
      state.init = val;
    },
    push_error: function (state, msg) {
      this.commit("push_alert", { message: msg, show: 5, type: "error" });
    },
    push_alert: function (state, data) {
      data["show"] = 5;
      let last = state.alerts[state.alerts.length - 1];
      if (last && last.message === data.message && last.type === data.type) {
        Vue.set(last, "count", last.count ? last.count + 1 : 2);
        Vue.set(last, "show", 40);
      } else {
        state.alerts.push(data);
      }
    },
    clear_errors: function (state) {
      state.alerts = state.alerts.filter((x) => x.type !== "error");
    },
    clear_alerts: function (state) {
      state.alerts = [];
    },

    //set_status_message(state, val) {
    //    state.status_message = val
    //},
    set_data(state, msg) {
      Vue.set(state.data, msg.field, msg.data);
    },
  },
  actions: {
    async ping(context) {
      if (!context.state.user.user.authenticated) {
        return;
      }
      const channel = context.state.channels.current_channel;
      await axios.post("/api/ping", {
        channel_id: channel ? channel.id : null,
      });
    },
  },
  modules: {
    user: User,
    player: Player,
    notifications: Notifications,
    chat: Chat,
    channels: Channels,
    websocket: Websocket,
    categories: Categories,
  },
});

export default store;
