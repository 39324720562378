import axios from "axios";

const state = {
  categories: [],
};

const mutations = {
  setCategories: (state, payload) => (state.categories = payload),
};

const getters = {
  categories: (state) => state.categories,
};

const actions = {
  async getCategories({ commit }) {
    const request = await axios.get("/api/categories");
    commit("setCategories", request.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
