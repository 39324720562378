import axios from "axios";

export default {
  namespaced: true,
  state: {
    notifications: [],
    since: null,
  },
  mutations: {
    set_since: function (state, val) {
      state.since = val;
    },
    set_notifications: function (state, val) {
      state.notifications = val;
    },
  },
  getters: {
    notifications: (state) => {
      return state.notifications;
    },
    has_unseen(state) {
      return state.notifications.some(x => !x.seen)
    }
  },
  actions: {
    delete_notification(context, notification_id) {
      context.commit('set_notifications',
        context.state.notifications.filter(x => x.id !== notification_id))
    },
    push_notifications: function (context, val) {
      context.commit("set_notifications", [...val, ...context.state.notifications].slice(0, 10))
    },
    fetch_notifications(context) {
      axios
        .get("/api/notifications", { params: { since: context.state.since } })
        .then((response) => {
          let notifications = response.data;
          context.dispatch("push_notifications", notifications);
          if (notifications.length)
            context.commit("set_since", notifications.slice(-1)[0].timestamp);
        });
    },
  },
};
