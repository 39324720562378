export function maybe_ignore_error(e) {
  if (e.response) {
    if ([400, 401, 403].indexOf(e.response.status) === -1) {
      throw e;
    }
  } else {
    throw e;
  }
}

import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;

export function ignore_redirect(e) {
  if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
    Promise.reject(e);
  }
}
