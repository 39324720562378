import axios from "axios";

export default {
  namespaced: true,
  state: {
    user: { authenticated: false, roles: [] },
  },
  mutations: {
    set_user: function (state, val) {
      state.user = Object.assign(state.user, val);
    },
    unset_user: function (state) {
      state.user = { authenticated: false, roles: [] };
    },
  },
  getters: {
    user: (state) => state.user,
    is_authenticated: function (state) {
      //if (state.user === null)
      //    return null;
      //else
      return state.user.authenticated;
    },
    is_admin(state) {
      return (
        state.user &&
        state.user.authenticated &&
        state.user.roles.indexOf("admin") !== -1
      );
    },
  },
  actions: {
    async signin(context, user) {
      const response = await axios.post('/api/signin', user)
      context.commit('set_user', response.data)
      context.dispatch("notifications/fetch_notifications", null, { root: true });
      context.dispatch('reopen_now', {}, { root: true })
      await context.dispatch('subscribe', {item_type: 'user'}, {root: true})
    },
    async signup(context, data) {
      const response = await axios.post('/api/signup', data)
      context.commit('set_user', response.data)
      context.dispatch("notifications/fetch_notifications", null, { root: true });
      context.dispatch('reopen_now', {}, { root: true })
      await context.dispatch('subscribe', {item_type: 'user'}, {root: true})
    },
    async signout(context) {
      await context.dispatch('unsubscribe', {item_type: 'user'}, {root: true})
      await context.dispatch('channels/unsubscribe_current_channel', null, {root: true})
      await axios.get('/api/signout')
      context.commit('unset_user')
      context.dispatch('reopen_now', {}, { root: true })
    },
    get_user: function (context) {
      return axios
        .get("/api/me")
        .then((response) => response.data)
        .then((result) => {
          context.commit("set_user", result);
        });
    },
  },
};
