import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// Add sentry
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN, // eslint-disable-line
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
      logErrors: true,
    }),
    //new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1,
});

router.beforeEach((to, from, next) => {
  store.commit("clear_errors");
  next();
});

// Add utils as plugin
import UtilsPlugin from "./utils/plugin.js";
Vue.use(UtilsPlugin);

// Add axios
Vue.prototype.$http = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_URL; // eslint-disable-line
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        store.commit("user/unset_user");
        router.push("/signin");
      } else if (error.response.status === 400) {
        store.commit("push_error", error.response.data.message);
      } else if (error.response.status === 405) {
        store.commit("push_error", "Method Not Allowed");
      } else if (error.response.status === 404) {
        store.commit("push_error", "Not Found");
      } else if (error.response.status === 500) {
        store.commit("push_error", "Server Error");
      } else if (error.response.status === 403) {
        store.commit("push_error", "Permission Denied");
      }
    }
    return Promise.reject(error);
  }
);

// Add bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./custom.scss";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

// Add lodash
import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });

// Add vue-cropperjs
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component("vue-cropper", VueCropper);

// Add momentjs
Vue.use(require("vue-moment")); // eslint-disable-line

import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

// Sync window title with route name
router.afterEach((toRoute) => {
  window.document.title = toRoute.name
    ? `Tellysim - ${toRoute.name}`
    : "Tellysim";
});

// Add analytics
//import VueAnalytics from 'vue-analytics'
//Vue.use(VueAnalytics, {
//    id: '',
//    router
//})

new Vue({
  router,
  store,
  axios,
  render: function (h) {
    return h(App);
  },
  beforeCreate() {
    this.$store.dispatch("chat/restoreChatState");
    this.$store.dispatch("categories/getCategories");
  },
}).$mount("#app");
