<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_chat: true,
      pingInterval: null,
    };
  },
  async created() {
    this.$store.dispatch("open_websocket");

    this.$store.dispatch("ping");
    this.pingInterval = setInterval(() => {
      this.$store.dispatch("ping");
    }, 50 * 1000);
  },
  beforeDestroy() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }

    this.$store.dispatch("close_websocket");
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>
