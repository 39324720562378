import { maybe_ignore_error, ignore_redirect } from "./error.js";

export default {
  install(Vue) {
    Vue.prototype.$utils = {
      maybe_ignore_error,
      ignore_redirect,

      to_youtube_id(s) {
        return s.length === 11 ? s : Vue.prototype.$youtube.getIdFromUrl(s);
      },

      get_rank_color(rank) {
        if (rank === "admin") return "#fed530";
        if (rank === "channel_owner") return "#ffa500";
        if (rank === "junior_executive") return "#006400";
        if (rank === "network_vp") return "#00008b";
        if (rank === "ceo") return "#8b0000";
        return "#666666";
      },

      to_title_case(str) {
        return str
          .replace(/_/g, " ")
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");
      },
    };
  },
};
