const PROTOCOL = window.location.protocol === "https:" ? "wss://" : "ws://";
const HOST = window.location.host;
const API_ENDPOINT = "/api/ws";

const waitForOpenConnection = (socket) => {
  return new Promise((resolve, reject) => {
    if (socket && socket.readyState === state.socket.OPEN) {
      resolve()
    }
    const maxNumberOfAttempts = 10;
    const intervalTime = 200; //ms

    let currentAttempt = 0;
    const interval = setInterval(() => {
      if (currentAttempt > maxNumberOfAttempts - 1) {
        clearInterval(interval);
        reject(new Error("Maximum number of attempts exceeded"));
      } else if (socket && socket.readyState === socket.OPEN) {
        clearInterval(interval);
        resolve();
      }
      currentAttempt++;
    }, intervalTime);
  });
};

const state = {
  websocket_items: [],
  self_closed: false
};

const mutations = {
  add_websocket_item(state, item) {
    item.timestamp = Date.now();
    state.websocket_items.unshift(item);
    if (state.websocket_items.length > 400) {
      state.websocket_items = state.websocket_items.slice(0, 100);
    }
  },
};

const actions = {
  open_websocket(context) {
    const uri = PROTOCOL + HOST + API_ENDPOINT;
    let socket

    try {
      socket = new WebSocket(uri);
    } catch {
      context.dispatch('reopen_later')
      return
    }

    socket.onmessage = (e) => context.dispatch("handle_message", e);
    socket.onopen = (e) => context.dispatch("handle_open", e);
    socket.onclose = (e) => context.dispatch("handle_close", e);
    socket.onerror = (e) => context.dispatch("handle_error", e);

    context.state.socket = socket;
  },
  close_websocket(context) {
    context.state.socket.close();
  },
  async handle_open(context, e) {
    context.commit("add_websocket_item", { name: "opened", event: e });
    if (context.state.timeout) {
      clearTimeout(context.state.timeout);
      context.state.timeout = 0;
    }
    context.state.attempts = 1;

    // resubscribe to user and channel pubsub
    if (context.rootState.user.user.authenticated) {
      await context.dispatch('subscribe', {item_type: 'user'})
    }
    if (context.rootState.channels.current_channel) {
      // while this should actually only be run after reopen
      // (since otherwise channel was already properly set),
      // the websocket subscription happens before channel is set 
      // so this shouldn't be called in the initial open in practice
      await context.dispatch('channels/set_current_channel',
        context.rootState.channels.current_channel)
    }
  },
  async send_message({ state }, msg) {
    await waitForOpenConnection(state.socket);
    state.socket.send(JSON.stringify(msg));
  },
  async subscribe({ state }, {item_type, item_id}) {
    await waitForOpenConnection(state.socket);
    await state.socket.send(JSON.stringify({type: 'subscribe', item_type, item_id}))
  },
  async unsubscribe({ state }, {item_type, item_id}) {
    await waitForOpenConnection(state.socket);
    await state.socket.send(JSON.stringify({type: 'unsubscribe', item_type, item_id}))
  },
  handle_message(context, e) {
    let msg = JSON.parse(e.data);
    context.commit("add_websocket_item", {
      name: "message",
      event: e,
      message: msg,
    });

    if (msg.name === "new-chat-message") {
      context.commit("chat/push_new_message", msg.data);
    } else if (msg.name === "active-user-ids") {
      if (context.rootState.channels.current_channel.id === msg.channel_id) {
        context.rootState.channels.active_user_ids = msg.data
      }
    } else if (msg.name === 'channel-max-bid') {
      if (context.rootState.channels.current_channel.id === msg.channel_id) {
        context.rootState.channels.channel_max_bid = msg.data;
      }
    } else if (msg.name === "timeslot-changed") {
      context.commit("player/set_timeslot", msg.data);
    } else if (msg.name === "user-changed") {
      if (msg.data.id === context.rootState.user.user.id) {
        context.commit("user/set_user", msg.data);
      }
    } else if (msg.name == "notification-added") {
      context.dispatch("notifications/push_notifications", [msg.data]);
    } else if (msg.name == 'notification-deleted') {
      context.dispatch('notifications/delete_notification', msg.data.notification_id)
    } else if (msg.name == "playback-changed") {
      context.commit("player/set_player_state", msg.data);
      context.commit("player/reset_votes");
    } else if (msg.name == "votes-changed") {
      context.commit("player/set_votes", msg.data);
    } else if (msg.name == 'channel-viewers-changed') {
      context.commit('channels/setViewers', msg.data)
    } else if (msg.name === 'leaderboard-changed') {
      context.commit('set_leaderboard', msg.data)
    } else if (msg.name === 'channel-added') {
      context.commit('channels/channel_added', msg.data)
    } else if (msg.name === 'channel-deleted') {
      context.commit('channels/channel_deleted', msg.data)
    } else if (msg.name === 'channel-changed') {
      throw 'channel-changed ws message not implemented yet in ui'
    } else {
      console.error('unknown ws message', msg)
    }
  },
  handle_close(context, e) {
    context.commit("add_websocket_item", { name: "closed", event: e });
    console.log("close", e);
    if (!context.state.self_closed) {
      context.dispatch("reopen_later");
    }
    context.state.self_closed = false
  },
  handle_error(context, e) {
    context.commit("add_websocket_item", { name: "error", event: e });
    console.log("error", e);
    context.dispatch("reopen_later");
  },
  reopen_now(context) {
    context.state.self_closed = true
    context.dispatch('close_websocket')
    context.dispatch('open_websocket')
  },
  reopen_later(context) {
    if (!context.state.timeout) {
      context.state.socket = null;
      let backoff =
        1000 + 1000 * Math.random() * Math.pow(2, context.state.attempts);
      console.log(`ws closed, reopening in ${backoff}`);
      context.state.timeout = setTimeout(
        () => context.dispatch("open_websocket"),
        backoff
      );
      context.state.attempts = Math.min(context.state.attempts + 1, 8);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
