import axios from "axios";

export default {
  namespaced: true,
  state: {
    channels: [],
    current_channel: null,
    viewers: null,
    active_user_ids: [],
    channel_max_bid: null
  },
  mutations: {
    setViewers(state, viewers) {
      state.viewers = viewers.sort((a, b) => {
        return a.active === b.active ? 0 : a.active ? -1 : 1
      })
    },
    sortChannels(state) {
      state.channels = state.channels.sort((a, b) => {
        if (a.official === b.official) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        }
        return a.official ? -1 : 1
      })
    },
    channel_added(state, channel) {
      if (!state.channels.find(x => x.id === channel.id)) {
        state.channels.push(channel)
        this.commit('channels/sortChannels')
      }
    },
    channel_deleted(state, channel) {
      if (state.channels.find(x => x.id === channel.id)) {
        state.channels = state.channels.filter(x => x.id !== channel.id)
        this.commit('channels/sortChannels')
      }
    },
  },
  getters: {
    channels: (state) => state.channels,
    getCurrentChannel: (state) => state.current_channel,
    getCurrentUserChannels: (state, getters, rootState, rootGetters) =>
      state.channels.filter((ch) => ch.user_id === rootGetters["user/user"].id),
    getCurrentChannelViewers: (state) => state.viewers,
  },
  actions: {
    async set_initial_channel(context) {
      context.dispatch("set_current_channel", context.state.channels[0]);
    },
    async fetch_channels(context) {
      context.state.channels = (await axios.get("/api/channels")).data;
      context.commit('sortChannels')
    },
    async unsubscribe_current_channel(context) {
      if (context.state.current_channel) {
        await context.dispatch(
        'unsubscribe', {
          item_type: 'channel', item_id: context.state.current_channel.id
        }, {root: true})
      }
    },
    async set_current_channel(context, channel) {
      await context.dispatch('unsubscribe_current_channel')
      context.state.current_channel = channel;
      if (context.rootState.user.user.authenticated) {
        const resp = await axios.post('/api/join-channel', {channel_id: channel.id})
        context.commit('setViewers', resp.data.viewers)
      }
      await context.dispatch('subscribe', {item_type: 'channel', item_id: channel.id}, {root: true})

      const playback = (await axios.get('/api/current-playback', {params: {channel_id: channel.id}})).data
      context.commit("player/set_player_state", playback, { root: true })

      if (playback) {
        const votes = (await axios.get('/api/vote', {params: { playback_id: playback.id }})).data
        context.commit("player/set_votes", votes, { root: true })
      } else {
        context.commit("player/reset_votes", null, { root: true })
      }

      context.dispatch("chat/fetch_messages", null, { root: true });

      const timeslot = (await axios.get('/api/current-timeslot',
        {params: {channel_id: channel.id}})).data
      context.commit('player/set_timeslot', timeslot, { root: true })

      context.rootState.channels.channel_max_bid = (await axios.get('/api/channel-max-bid',
        {params: {channel_id: channel.id}})).data

      await context.dispatch("ping", null, { root: true });

      if (channel) {
        context.state.active_user_ids = (
          await axios.get("/api/active-user-ids", {
            params: { channel_id: channel.id },
          })
        ).data;
      } else {
        context.state.active_user_ids = [];
      }
    },
    //async get_current_channel_viewers({ commit, getters }) {
    //  if (!getters.getCurrentChannel) return;
    //  axios
    //    .get(`/api/channel-viewers?channel_id=${getters.getCurrentChannel.id}`)
    //    .then((res) => {
    //      commit("setViewers", res.data);
    //    });
    //},
  },
};
